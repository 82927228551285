.droits-container {
  padding: 0;
}

.background-image-droits {
  background: url('../../Assets/backdroits.jpg') no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.background-image-droits::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Adjust color and opacity for tint */
  z-index: 1;
}

.overlay-text-droits-singlepage {
  font-family: raleway;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  z-index: 2;
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #fff; /* Text color to contrast with the background tint */
  padding: 20px;
  width: 100%; /* Optional: Set width to full to make the text container span across the image */
}

.convention-container {
  padding: 20px;
}

.convention-title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.convention-text {
  font-size: 1em;
  margin-bottom: 10px;
}

.convention-subtitle {
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.convention-list {
  list-style-type: disc;
  margin-left: 20px;
}

.convention-link {
  color: #007bff;
  text-decoration: none;
}

.convention-link:hover {
  text-decoration: underline;
}

.convention-image {
  max-width: 100px !important;
  height: auto;
  margin-top: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  transition: all ease-in-out 0.7s;
  cursor: pointer !important;
  border-radius: 15px;
}
.convention-image:hover{
  scale: 1.1;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

/* Updated to match the correct class name */
.definitions-convention {
  background-color: rgb(25, 80, 147) !important; /* Background color set to blue */
  padding: 15px; /* Optional: Added padding for better appearance */
  color: white; /* Optional: Ensures the text is readable */
  border-radius: 5px; /* Optional: Added rounded corners */
}
.definitions-convention h2{
  color: white !important;
  margin-bottom: 1em;
  border-bottom: solid 2px white;
}
.definitions-convention ul{
  color: white !important;
}
.definitions-convention p{
  margin-top: 1em;
  border-top: solid 2px white;
  padding-top: 2em;
}
.liens-convention{
  border-top:solid 2px black !important;
  padding: 2em o !important;
}

