.Wikid-title {
    margin-top: 20px;
    color: #333;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body {
    background-color: #fff;
    border-radius: 5px;
}
.background-image-chercheurs {
    background: url('../../Assets/backchercheurs.png') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;   
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-chercheurs::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
    z-index: 1;
}
.overlay-text-chercheurs{
    color: white;
    z-index: 2;
}
.chercheurs-description{
    font-size:1.3em;
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
