.droits-container {
    padding: 0;
  }
  
  .background-image-droits {
    background: url('../../Assets/backdroits.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-droits::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Adjust color and opacity for tint */
    z-index: 1;
  }
  
  .overlay-text-droits-singlepage {
    font-family: raleway;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    z-index: 2;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff; /* Text color to contrast with the background tint */
    padding: 20px;
    width: 100%; /* Optional: Set width to full to make the text container span across the image */
    
  }
  .convention-container {
    padding: 20px;
  }
  
  .convention-title {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .convention-text {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .convention-subtitle {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .convention-list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .convention-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .convention-link:hover {
    text-decoration: underline;
  }
  
  .convention-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
  }
  /* Convention Table Styling */
.regulations-table {
  margin-top: 2em;
  background-color: #f8f9fa;
  padding: 1.5em;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

}

.regulations-table h2 {
  margin-bottom: 1em;
  text-align: center;
  font-size: 1.5em;
  color: #0056b3;
  font-weight: bold;
}

.regulations-table table {
  width: 100%;
  border:solid 2px black ;
  table-layout: fixed; 
}

.regulations-table table tbody tr:nth-child(odd) {
  background-color: #e9ecef;
}

.regulations-table table tbody tr:nth-child(even) {
  background-color: #fdfdfe;
}

.regulations-table table td {
  padding: 1em;
  border: 2px solid #000000;
  vertical-align: top;

}


.regulations-table table td p {
  margin: 0;
  color: #343a40;
  font-size: 1em;
  line-height: 1.5;
  color: white !important;
}


.td-constitution-2022 {
  background-color: #00BFFF; /* Light blue */
  color: white;
}

.td-constitution-2014 {
  background-color: #6AA84F; /* Light green */
  color: white;
}

.td-law-2005-83 {
  background-color: #007F7F; /* Light yellow */
  color: black;
}

.td-law-2016-61 {
  background-color:  #408000; /* Light orange */
  color: black;
}

.td-law-83-2005 {
  background-color: #003f7f; /* Dark blue */
  color: white;
}

.td-decree-1996 {
  background-color: #6AA84F; /* Lighter blue */
  color: black;
}

.td-decree-2005-3029 {
  background-color: #FFA07A; /* Light yellow */
  color: black;
}

.td-decree-2005-3086 {
  background-color: #a7d85e; /* Light green */
  color: white;
}
