.wikidi-article-preview-container {
  text-align: center;
  margin: 20px;
}

.wikidi-article-card {
  max-width: 90vw;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.wikidi-image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40em;
}

.wikidi-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1;
}

.wikidi-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.wikidi-dots-section {
  margin-top: 10px;
}

.wikidi-dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.wikidi-dot.wikidi-active {
  background-color: #717171;
}

.wikidi-article-image {
  width: 100%;
  height: auto;
  transform: scale(1);
  filter: brightness(70%);
}

.transition-scale {
  animation: scaleUp 6s ease-in-out forwards;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.card-body {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.card-body.fade-out {
  opacity: 0;
}

.card-body.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.hidden {
  opacity: 0;
}
.card-body-wikiphedia:hover{
  transform: none !important; 
}
.wikidi-article-card .card-body-wikiphedia .card-text {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) */
  max-width: 100%; /* Ensure the text doesn't exceed the container width */
}
.card-body-wikiphedia .single-line-content {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
