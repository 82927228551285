.Wikid-title {
    margin-top: 20px;
    color: #333;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body {
    background-color: #fff;
    border-radius: 5px;
}
.background-image-acteursc {
    background: url('../../Assets/backacteurssc.png') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;   
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-acteursc::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
    z-index: 1;
}
.overlay-text-acteursc{
    color: white;
    z-index: 2;
}
.acteursc-description{
    font-size:1.3em;
}
.card-text-truncatedd {
    white-space: nowrap;       /* Prevents text from wrapping */
    overflow: hidden;          /* Hides overflow text */
    text-overflow: ellipsis;   /* Adds ellipsis (...) for overflow text */
  }

  /* ActeurScPl.css */




/* ActeurScPl.css */

.latest-story-card {
    height: 900px; /* Adjust height as needed */
}

.latest-story-image {
    height: 50%; /* Adjust height of image as needed */
    object-fit: cover; /* Ensure the image covers the card properly */
}

.wikid-card-image {
    height: 200px; /* Adjust height for other images */
    object-fit: cover; /* Ensure images cover their containers */
}
