/* Existing styles remain unchanged */

.concept-section {
    background: #ffffff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 1.4em;
    font-family: 'Raleway', sans-serif;
}

.flex-grow-1 {
    flex: 1;
}

.description-container {
    background: radial-gradient(circle, #ffffff, #f0f0f0);
    padding: 1em;
    border-radius: 15px;
    margin-bottom: 2em;
}

.description {
    background: #ffffff;
    border-radius: 25px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Raleway', sans-serif;
}

.description-title {
    font-size: 2.5em;
    font-weight: bold;
    color: #0056b3;
}

.description-p {
    font-size: 1.2em;
}

.concept-description {
    margin-bottom: 20px;
    font-size: 1.6em;
    color: #333;
    font-family: 'Raleway', sans-serif;
}

.concept-description h2 {
    color: #0056b3;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.concept-icon {
    font-size: 6em;
    color: #0056b3;
    margin-bottom: 10px;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
    font-family: 'Raleway', sans-serif;
    color: #333;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 767px) {
    .concept-section {
        padding: 10px !important; /* Reduce padding */
        margin-top: 1em !important; /* Adjust margin */
    }

    .description-container {
        padding: 0.5em !important; /* Reduce container padding */
    }

    .description-title {
        font-size: 2em !important; /* Reduce title font size */
    }

    .description-p {
        font-size: 1em !important; /* Reduce paragraph font size */
        padding: 0 10px !important; /* Add padding for text inside */
    }

    .concept-icon {
        font-size: 4em !important; /* Reduce icon size */
        margin-bottom: 5px !important; /* Reduce margin below icon */
    }

    ul {
        padding-left: 15px !important; /* Reduce padding for list */
    }

    /* Stack sections vertically on small screens */
    .flex-grow-1 {
        flex-basis: 100% !important; /* Make each column take full width */
        max-width: 100% !important; /* Ensure full width for each section */
        margin-bottom: 1.5em !important; /* Add margin between sections */
    }
}
