.Recommandation-container {
  padding: 0;
}

.background-image-Recommandation {
  background: url('../../../../Assets/backrecommandation.jpg') no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.background-image-Recommandation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Adjust color and opacity for tint */
  z-index: 1;
}

.overlay-text-Recommandation {
  font-family: raleway;
  padding: 20px;  border-radius: 10px;
  text-align: left;
  z-index: 2;
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #fff; /* Text color to contrast with the background tint */
  padding: 4em;
  width: 100%; /* Optional: Set width to full to make the text container span across the image */
  
}
.Recommandation-titre{
  font-size:3em;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.Recommandation-description{
  font-size:1.5em;
  padding:2em 3em 0 3em;
}
.pdf-list {
  padding: 20px;
}

.savoirlab-titre{
  color:white;
}

.rec-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin-top: 1em;
  padding: 4em;
}



.rec-card-title {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #007bff;
}

.rec-card-description {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.rec-card-button {
  background-color: #007bff;
  color: white;
  overflow: hidden;
  position: relative;
  width: 30vw;
  
}

.rec-card-button:hover {
  background-color: #0056b3;
}

.rec-card-button .Recommandation-button-text {
  display: inline-block;
  transition: transform 0.5s ease;
  font-size: 1.5rem;
}

.rec-card-button .Recommandation-button-icon {
  position: absolute;
  right: -1.25rem;
  font-size: 1.7em;
  transition: right 0.5s ease;
}

.rec-card-button:hover .Recommandation-button-text {
  transform: translateX(-1.7em);
}

.rec-card-button:hover .Recommandation-button-icon {
  right: 20px;
  
}
@media (max-width: 768px) {
  .Recommandation-titre{
    font-size: 2em !important;
  }
  .Recommandation-description{
    font-size: 1em !important;
  }
  .rec-card-title{
    font-size: 1.5em !important;
  }
  .rec-card-description{
    font-size: 1.2em !important;
  }
  .Recommandation-button-text{
    font-size: 1.2em !important;
  }
  .Recommandation-button-icon{
    display: none;
  }
  .rec-card{
    padding:10px !important;
  }
  .rec-card-button {
    background-color: #007bff;
    color: white;
    position: relative;
    width: 50vw !important; 
  }

}
@media (max-width: 425px) {
  .Recommandation-titre{
    font-size: 1.7em !important;
  }
  .Recommandation-description{
    font-size: 0.8em !important;
  }
  .rec-card-title{
    font-size: 1.2em !important;
  }
  .rec-card-description{
    font-size: 1em !important;
  }
  .Recommandation-button-text{
    font-size: 1em !important;
    white-space: nowrap;
  }
  .Recommandation-button-icon{
    display: none;
  }
  .rec-card{
    padding:0 !important;
  }
}