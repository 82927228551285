/* src/App.css */
.app {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    overflow-x: hidden;

  }
  
  .app-header {
    background-color: #ffffff;
    padding: 1rem;
    color: white;
  }
  
  .app-content {
    flex: 1;
    padding: 1rem;
  }
  /* Add this to your CSS file */
body.rtl {
  direction: rtl;
  text-align: right; /* Optional: Align text to the right */
}

/* If you need to specifically target certain elements */
.rtl .some-element {
  text-align: right; /* Adjust as needed */
}
