.Accessibility-container {
    padding: 0;
  }
  
  .background-image-Accessibility {
    background: url('../../../Assets/backaccessibility1.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-Accessibility::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust color and opacity for tint */
    z-index: 1;
  }
  
  .overlay-text-Accessibility {
    font-family: raleway;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    z-index: 2;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff; /* Text color to contrast with the background tint */
    padding: 4em;
    width: 100%; /* Optional: Set width to full to make the text container span across the image */
    
  }
  .Accessibility-titre{
    font-size:3em;
  }
  .Accessibility-description{
    font-size:1.5em;
  }
  .pdf-list {
    
    padding: 20px;
  }
  .savoirlab-titre{
    color:white;
  }

  .droit-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    margin-top: 1em;
    padding: 4em;
  }
  
 
  
  .droit-card-title {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #007bff;
  }
  
  .droit-card-description {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  
  .droit-card-button {
    background-color: #007bff;
    color: white;
    overflow: hidden;
    position: relative;
    width: 30vw;
    
  }
  
  .droit-card-button:hover {
    background-color: #0056b3;
  }

.droit-card-button .Accessibility-button-text {
    display: inline-block;
    transition: transform 0.5s ease;
    font-size: 1.5rem;
}

.droit-card-button .Accessibility-button-icon {
    position: absolute;
    right: -1.25rem;
    font-size: 1.7em;
    transition: right 0.5s ease;
}

.droit-card-button:hover .Accessibility-button-text {
    transform: translateX(-1.7em);
}

.droit-card-button:hover .Accessibility-button-icon {
    right: 20px;
    
}
@media (max-width: 768px) {
  .Accessibility-titre{
    font-size: 2.5em !important;
  }
  .Accessibility-description{
    font-size: 1.3em !important;
  }
  .droit-card-title{
    font-size: 1.5em !important;
  }
  .droit-card-description{
    font-size: 1.2em !important;
  }
  .Accessibility-button-text{
    font-size: 1.2em !important;
  }
  .Accessibility-button-icon{
    display: none;
  }
  .droit-card{
    padding:10px !important;
  }
  .droit-card-button {
    background-color: #007bff;
    color: white;
    position: relative;
    width: 50vw !important; 
  }

}
@media (max-width: 425px) {
  .Accessibility-titre{
    font-size: 2.2em !important;
  }
  .Accessibility-description{
    font-size: 1.2em !important;
  }
  .droit-card-title{
    font-size: 1.2em !important;
  }
  .droit-card-description{
    font-size: 1em !important;
  }
  .Accessibility-button-text{
    font-size: 1em !important;
    white-space: nowrap;
  }
  .Accessibility-button-icon{
    display: none;
  }
  .droit-card{
    padding:0 !important;
  }
}