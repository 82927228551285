/* SinglePost.css */

/* Typography */
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&family=Ubuntu:wght@300;400;700&display=swap');

.single-post-container{
  margin-top:0px !important;
}

.single-post-info {
  font-size: .875rem;
  color: #707070;
  margin-bottom: 1.5em;
}

.single-post-body {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 2em;
}
.single-post-image{
  width:100vh !important;
  height: auto !important;
}

@media (max-width: 768px) {
  .single-post-body {
    font-size: 1rem;
  }
}

.single-post-container {
  max-width: 70em;
  margin: 0 auto;
  padding: 2em 1em;
}

.single-post-image {
  width: 100vw;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;

}
