
.DocumentPl-container {
    padding: 0;
  }
  
  .background-image-DocumentPl {
    background: url('../../Assets/backdocument.png') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-DocumentPl::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust color and opacity for tint */
    z-index: 1;
  }
  
  .overlay-text-DocumentPl {
    font-family: raleway;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    z-index: 2;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff; /* Text color to contrast with the background tint */
    padding: 4em;
    width: 100%; /* Optional: Set width to full to make the text container span across the image */
    
  }
  .DocumentPl-titre{
    font-size:3em;
  }
  .DocumentPl-description{
    font-size:1.2rem;
    padding: 0.5em !important;
  }
  .pdf-list {
    padding: 20px;
  }
  .pdf-image {
    width: 90px;  /* Adjust the width as needed */
    height: auto;
    object-fit: contain;  /* Ensures the image maintains its aspect ratio */
}
.card-plaidoyer{
  background-color: #e1ebff !important;
  box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.7);
  border-radius: 15px;
}