.Wikid-title {
    margin-top: 20px;
    color: #333;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body {
    background-color: #fff;
    border-radius: 5px;
}
.background-image-sportifs {
    background: url('../../Assets/backsportifs.png') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;   
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-sportifs::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
    z-index: 1;
}
.overlay-text-sportifs{
    color: white;
    z-index: 2;
}
.sportifs-description{
    font-size:1.3em;
}
.card img.wikid-card-image {
    height: 200px !important; /* Force the height */
    width: 100%; /* Ensure full width */
    object-fit: cover; /* Cover the space while maintaining aspect ratio */
}
.latest-wikid{
    width: 100%;
    height: 500px;
    object-fit:cover;
}
