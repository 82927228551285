.background-image-Communication {
    background: url('../../../../Assets/backcommunication1.png') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-Communication::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust color and opacity for tint */
    z-index: 1;
  }

  .overlay-text-Communication {
    font-family: raleway;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    z-index: 2;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff; /* Text color to contrast with the background tint */
    width: 100%; /* Optional: Set width to full to make the text container span across the image */
    padding: 0 5em 0 5em;
    
  }
  .Communication-titre{
    font-size:3em;
    bottom: 0 !important;
  }

.guide-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: 90vw;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    margin-top: 2em;
  }
  
  .guide-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    margin-top: 5em;
  }
  
  .guide-preview {
    position: relative;
    width: 150px;
    margin-bottom: 10px;
  }
  
  .guide-image {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.7);
  }
  
  .download-button-guide {
    display: block;
    width: 50px !important; /* Adjust width as needed */
    height: 50px !important; /* Adjust height as needed */
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%); /* Centers the image horizontally */
    transition: all ease-in-out 0.3s;
  }
  .download-button-guide:hover {
    cursor: pointer !important;
    scale: 1.1;
  }
  .french-link {
    font-size: 14px;
    color: #0056b3;
    text-decoration: none;
    margin-top: 20px;
  }
  
  .french-link:hover {
    text-decoration: underline;
  }
  
  .guide-content {
    flex: 1;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .guide-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .guide-content h3 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .context-section h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #444;
  }
  
  .context-section p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.6;
    color: #555;
  }
  
  .show-more {
    color: red;
    font-weight: bold;
  }
  