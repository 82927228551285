.background-image-coalition {
    background: url('../../../../../Assets/backcharte.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  .background-image-coalition::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust color and opacity for tint */
    z-index: 1;
  }

  .overlay-text-Communication {
    font-family: raleway;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    z-index: 2;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff; /* Text color to contrast with the background tint */
    width: 100%; /* Optional: Set width to full to make the text container span across the image */
    padding: 0 5em 0 5em;
    
  }
  .Communication-titre{
    font-size:3em;
  }

.charte-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    margin-top: 2em;
  }
  
  .charte-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    margin-top: 5em;
  }
  
  .charte-preview {
    position: relative;
    width: 150px;
    margin-bottom: 10px;
  }
  
  .charte-image {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .download-button {
    display: block;
    width: auto;
    height: auto;
    border-radius: 15px;
    background: #000;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
  }
  
  .french-link {
    font-size: 14px;
    color: #0056b3;
    text-decoration: none;
    margin-top: 20px;
  }
  
  .french-link:hover {
    text-decoration: underline;
  }
  
  .charte-content {
    flex: 1;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .charte-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .charte-content h3 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .context-section h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #444;
  }
  
  .context-section p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.6;
    color: #555;
  }
  
  .show-more {
    color: red;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    .droits-titre{
      font-size: 2.5em !important;
    }
    .droits-description{
      font-size: 1.3em !important;
    }
    .droit-card-title{
      font-size: 1.5em !important;
    }
    .droit-card-description{
      font-size: 1.2em !important;
    }
    .droits-button-text{
      font-size: 1.2em !important;
    }
    .droits-button-icon{
      display: none;
    }
    .droit-card{
      padding:10px !important;
    }
    .droit-card-button {
      background-color: #007bff;
      color: white;
      position: relative;
      width: 50vw !important; 
    }
  
  }
  @media (max-width: 425px) {
    .droits-titre{
      font-size: 2.2em !important;
    }
    .droits-description{
      font-size: 1.2em !important;
    }
    .droit-card-title{
      font-size: 1.2em !important;
    }
    .droit-card-description{
      font-size: 1em !important;
    }
    .droits-button-text{
      font-size: 1em !important;
      white-space: nowrap;
    }
    .droits-button-icon{
      display: none;
    }
    .droit-card{
      padding:0 !important;
    }
  }