.audio-image {
  position: relative; /* Ensure the pseudo-element is positioned relative to this container */
  width: 100vw;
  height: 100vh;
}

.audio-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.audio-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the color and opacity as needed */
  z-index: 1;
}
.audio-list{
  margin-top:0;
  background-color: transparent !important;
  border-top: solid 10px #0056b3  ;
}
.audio-page{
  background-color: transparent !important;
}
/* Styling for the audio player */
/* General Styles */
/* Container for the audio player */
.audio-player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Each individual audio player */
.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.audio-info {
  text-align: center;
  margin-bottom: 1rem;
}

.audio-title {
  margin: 1em 0 1em 0;
  color: #0056b3;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  font-size:4em;
}

.audio-description {
  font-size: 1rem;
  color: #666;
}

.audio-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.progress-container {
  flex: 1;
  height: 10px;
  background: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: #007bff;
  border-radius: 5px;
}

.volume-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.volume-controls input[type="range"] {
  cursor: pointer;
}

.time {
  font-size: 0.875rem;
  color: #333;
}

.audio-player-podcast-audio {
  width: 100%; /* Adjust the width as needed */
}

/* Smaller screen sizes */
@media (max-width: 768px) {
  .audio-player-podcast-audio {
      width: 100%;
      transform: scale(0.9);
  }
  .audio-title {
    margin: 1em 0 1em 0;
    color: #0056b3;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    font-size:2.5em !important;
  }
}

@media (max-width: 576px) {
  .audio-player-podcast-audio {
      width:100%;
      transform: scale(0.9);
  }
  .audio-title {
    margin: 1em 0 1em 0;
    color: #0056b3;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    font-size:1em ;
  }
}