.appui-acteurs-container {
    padding: 0;
}

.appui-acteurs-background {
    background: url('../../../../Assets/backaccessibility1.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.appui-acteurs-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust color and opacity for tint */
    z-index: 1;
}

.appui-acteurs-overlay {
    font-family: Raleway, sans-serif;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    z-index: 2;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff; /* Text color to contrast with the background tint */
    padding: 4em;
    width: 100%; /* Optional: Set width to full to make the text container span across the image */
}

.appui-acteurs-title {
    font-size: 3em;
}

.appui-acteurs-content h2 {
    color: #007bff;
}

.appui-acteurs-content h3 {
    color: #004085;
    background-color: #cce5ff;
    padding: 10px;
}

.appui-acteurs-content p {
    text-align: justify;
}

.appui-acteurs-table th {
    background-color: #e9ecef;
    text-align: center;
    font-weight: bold;
    border: solid 1px black;
}

.appui-acteurs-table td {
    vertical-align: middle;
    border: solid 1px black;
}
.fiche9-table{
    border: solid 1px black;
}