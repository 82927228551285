
.custom-container {
  width: 100vw;
}

.custom-thumbnail-date-day {
  color: rgb(255, 255, 255);
}

.custom-thumbnail-date-month {
  color: rgb(255, 255, 255);
}
.custom-card-img-top{
  width: 30em;
  height:20em;
  object-fit: cover !important;
}

.custom-thumbnail {
  -webkit-touch-callout: none;
  border-radius: 3px;
  box-sizing: initial;
  height: 52px;
  min-width: 52px;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 52px;
}

.custom-thumbnail .custom-thumbnail-date span,
.custom-thumbnail .custom-thumbnail-date span {
  display: inline-block;
  line-height: 1;
}

.custom-thumbnail .custom-thumbnail-date .custom-thumbnail-date-day {
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
  position: relative;
  text-align: center;
  top: 4px;
  width: 100%;
}

.custom-thumbnail .custom-thumbnail-date .custom-thumbnail-date-month {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.custom-tags-list-town {
  background-color: #198754;
}

.custom-tags-list span {
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 4px;
  margin-right: 5px;
  padding: 4px 6px;
  position: relative;
}

.custom-text-over {
  font-size: 1.3em;
  font-weight: 900;
  color: #fff;
  padding: 20px;
}

.custom-card {
  border: 0 !important;
  border-radius: 0 !important;
}

.custom-card-img-top {
  border-radius: 0 !important;
}

.custom-image-container {
  position: relative;
  overflow: hidden;
}

.custom-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 57%);
  z-index: 1;
}

.custom-image-container .custom-card-img-top {
  position: relative;
  z-index: 0;
}

.custom-tags-list-town {
  background-color: #9c27b0;
}
.events-title{
  text-align: center;
  font-weight: bold;
  color: #0056b3;
}
.custom-bottom-left {
  position: absolute;
  bottom: 14px;
  left: 16px;
  z-index: 999;
}

.custom-bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #fff;
  font-size: 10px;
  z-index: 999;
}

.custom-top-right {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 999;
}

.custom-thumbnail {
  box-shadow: 0.5rem 0.5rem #00000085, -0.5rem -0.5rem #cccccca3;
}

.custom-card {
  border: 1px solid #eee;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.custom-tags-list span {
  font-size: 14px !important;
}

.custom-free-tag {
  background-color: #28a745;
  text-transform: uppercase;
  font-weight: 800;
}
