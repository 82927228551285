.opportunities-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    color: #0056b3;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.liens-utiles-description {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 2rem;
    color: black;
}

.opportunity-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
}

.opportunity-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.card-body {
    padding: 1.5rem;
}

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.opportunity-image {
    width: auto;
    height:20em; /* Maintains the aspect ratio */
    object-fit: cover !important; /* Ensures the image covers the entire space */
}
.card-text {
    font-size: 1rem;
}

@media (max-width: 768px) {
    .opportunities-title {
        font-size: 1.5rem;
    }

    .liens-utiles-description {
        font-size: 0.875rem;
    }

    .card-title {
        font-size: 1.125rem;
    }

    .card-text {
        font-size: 0.875rem;
    }
}

@media (max-width: 576px) {
    .opportunities-title {
        font-size: 1.25rem;
    }

    .liens-utiles-description {
        font-size: 0.75rem;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-text {
        font-size: 0.75rem;
    }
}